a {
  display: inline-block;
  position: relative;
  color: #cfe4ff;
  text-decoration: none;
}

a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(144, 159, 178);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

a:hover {
  color: rgb(144, 159, 178);
  cursor: pointer;
}

a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}