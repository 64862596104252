@font-face {
  font-family: "Neonneon";
  src: local("Neonneon"), url("../res/fonts/Neonneon.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "AppleTea";
  src: url("../res/fonts/AppleTea.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Electrolux";
  src: url("../res/fonts/Electrolux Sans.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Electrolux";
  src: url("../res/fonts/Electrolux Sans Bold.otf") format("opentype");
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.opaqueNav {
  opacity: 1;
}

.transparentNav {
  opacity: 0;
}

.transitionsNav {
  transition: opacity 2s ease-out;
  -moz-transition: opacity 2s ease-out;
  -webkit-transition: opacity 2s ease-out;
  -o-transition: opacity 2s ease-out;
}

.gone {
  display: none;
}
