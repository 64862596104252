body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opaque {
  opacity: 1;
  transition: opacity 2s ease-out;
  -moz-transition: opacity 2s ease-out;
  -webkit-transition: opacity 2s ease-out;
  -o-transition: opacity 2s ease-out;
}

.opaque.transparent {
  opacity: 0;
  transition: opacity 2s ease-out;
  -moz-transition: opacity 2s ease-out;
  -webkit-transition: opacity 2s ease-out;
  -o-transition: opacity 2s ease-out;
}

.gone {
  display: none;
}